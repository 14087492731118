import React from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

import Landing from "../pages/landing/Landing";

import Renato from "../pages/about/Renato";

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact={true} render={props => <Landing {...props} />} />
        <Route
          path="/home"
          exact={true}
          render={props => <Landing {...props} />}
        />
        <Route
          path="/renato"
          exact={true}
          render={props => <Renato {...props} />}
        />
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
