import React from "react";

const Tittle = props => {
  const { tittle, subtittle } = props.Tittle;

  return (
    <div className="p-4">
      <div className="lead font-weight-bold">{tittle}</div>
      <div className="text-muted">{subtittle}</div>
    </div>
  );
};

export default Tittle;
