import React, { useState } from "react";

import { Fade } from "react-reveal";

import Tittle from "../../../components/Tittle";
import LayoutSection from "../../../layouts/Section";
import { Col, Row } from "reactstrap";

const Educations = props => {
  const [educations] = useState(props.data);

  const Education = ({ education }) => {
    return (
      <Row>
        <Col>
          <Fade cascade>
            <div className="pb-3">
              <p className="my-0">
                <span className="text-uppercase font-weight-bold">
                  {education.school}, {education.start} – {education.end}
                </span>
                <span className="">
                  {education.obs ? ` (${education.obs})` : ""}
                </span>
              </p>
              <p className="my-0 font-italic">{education.course}</p>
            </div>
          </Fade>
        </Col>
      </Row>
    );
  };

  const Details = () => {
    return (
      <div>
        {educations.list.map((education, index) => (
          <Education education={education} index={index} key={index} />
        ))}
      </div>
    );
  };

  return (
    <LayoutSection
      {...props}
      Tittle={() => <Tittle Tittle={educations.tittle} />}
      Details={Details}
    />
  );
};

export default Educations;
