import React, { useEffect } from "react";

import { Container } from "reactstrap";

const Header = () => {
  let pageHeader = React.createRef();

  useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div
        id="header"
        className="page-header section-dark"
        style={{
          backgroundImage:
            "url(" + require("assets/img/antoine-barres.jpg") + ")"
        }}
        data-parallax={true}
        ref={pageHeader}
      >
        <div className="filter" />
        <div className="content-center">
          <Container>
            <div className="motto text-center">
              <h1 style={{ fontWeight: 600, textTransform: "uppercase" }}>
                Scarante Tecnologia
              </h1>
            </div>
            <div className="fog-low">
              <img alt="..." src={require("assets/img/fog-low.png")} />
            </div>
            <div className="fog-low right">
              <img alt="..." src={require("assets/img/fog-low.png")} />
            </div>
            <div className="motto text-center text-muted">
              <h3 style={{ fontWeight: 600 }}>
                Descomplique seu negócio, desenvolva seus sonhos.
              </h3>
            </div>
          </Container>
        </div>
        <div
          className="moving-clouds"
          style={{
            backgroundImage: "url(" + require("assets/img/clouds.png") + ")"
          }}
        />
      </div>
    </>
  );
};

export default Header;
