import React, { useState } from "react";

import { Fade } from "react-reveal";

import Tittle from "../../../components/Tittle";
import LayoutSection from "../../../layouts/Section";
import { Col, Row } from "reactstrap";

const Skills = props => {
  const [skills] = useState(props.data);

  const Skill = ({ skill }) => {
    return (
      <Fade cascade>
        <div>
          <div className="lead pb-2">
            <strong>
              <span className="text-warning pr-2">
                {skill.percent}
                <small>%</small>
              </span>
              {skill.name}
            </strong>
          </div>
          <div className="progress rounded" style={{ height: "7px" }}>
            <div
              className="progress-bar rounded bg-warning"
              role="progressbar"
              style={{ width: `${skill.percent}%` }}
              aria-valuenow={skill.percent}
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </Fade>
    );
  };

  const Details = () => {
    return (
      <Row>
        {skills.list.map((skill, index) => (
          <Col sm={4} key={index} className="pb-5">
            <Skill skill={skill} />
          </Col>
        ))}
      </Row>
    );
  };

  return (
    <LayoutSection
      {...props}
      Tittle={() => <Tittle Tittle={skills.tittle} />}
      Details={Details}
    />
  );
};

export default Skills;
