import React from 'react';

import { Col, Row } from "reactstrap";

const SectionDetails = props => {
  const { Component } = props;

  return (
    <Row className="h-100 p-4">
      <Col className="">
        <Component {...props} />
      </Col>
    </Row>
  );
}

export default SectionDetails;
