import React, { useState } from "react";

import Tittle from "../../../components/Tittle";
import PresentationName from "../../../components/PresentationName";
import Contact from "../../../components/Contact";
import LayoutSection from "../../../layouts/Section";
import { Col, Row } from "reactstrap";

const Contacts = props => {
  const [contacts] = useState(props.data);
  const [summary] = useState(props.summary);

  const Details = () => {
    return (
      <Row>
        <Col>
          <Row>
            { contacts.list.map((contact, index) => <Contact contact={contact} key={index}/>) }
          </Row>
          <hr />
          <Row className="py-4 align-items-center">
            <Col>
              <PresentationName data={summary} />
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
  
  return (
    <LayoutSection {...props} Tittle={() => <Tittle Tittle={contacts.tittle} />} Details={Details} />
  );
};

export default Contacts;