import React from "react";

import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";

const aboutUsItems = [
  {
    tittle: "Nossa visão",
    subtittle: "O que queremos",
    description:
      "Oferecer um serviço eficiente, ágil, rápido e com qualidade, entender as necessidades e, baseado na experiência, sugerir as melhores soluções.",
    icon: "nc-user-run"
  },
  {
    tittle: "Tecnologia",
    subtittle: "Como fazemos",
    description:
      "Estamos sempre estudando o mercado para oferecer as melhores soluções com as melhores tecnologias.",
    icon: "nc-spaceship"
  },
  {
    tittle: "História",
    subtittle: "Um pouco sobre nós.",
    description:
      "Com uma vasta experiência e uma longa história na área da tecnologia do seu fundador, Renato, a Scarante Tecnologia nasce, no auge do mercado tecnológico, naturalmente da necessidade e da vontade de oferecer um serviço diferenciado, visando sempre a agilidade, praticidade e qualidade.",
    icon: "nc-paper"
  }
];

const AboutUsCard = ({ item }) => {
  return (
    <Col md="4">
      <Card className="card-profile card-plain">
        <div className="card-avatar rounded">
          <div className="info">
            <div className="icon icon-info">
              <i className={`nc-icon ${item.icon}`} />
            </div>
          </div>
        </div>
        <CardBody>
          <a href="#pablo" onClick={e => e.preventDefault()}>
            <div className="author">
              <CardTitle tag="h4">{item.tittle}</CardTitle>
            </div>
          </a>
          <hr />
          <p className="card-description text-center">{item.description}</p>
        </CardBody>
        <CardFooter className="text-center"></CardFooter>
      </Card>
    </Col>
  );
};

const AboutUs = () => {
  return (
    <div id="about-us" className="section section-dark text-center">
      <Container>
        <h2 className="title">Sobre nossa empresa</h2>
        <Row>
          {aboutUsItems.map((item, index) => (
            <AboutUsCard key={index} item={item} />
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default AboutUs;
