import React, { useState } from "react";

import { Fade } from "react-reveal";

import Tittle from "../../../components/Tittle";
import LayoutSection from "../../../layouts/Section";
import { Col, Row } from "reactstrap";

const Portifolio = props => {
  const [examples] = useState(props.data);

  const Example = ({ example }) => {
    return (
      <Row className="mb-4">
        <Col>
          <Fade cascade>
            <div className="pb-3">
              <p className="my-0">
                <span className="text-uppercase font-weight-bold">
                  {example.tittle}
                </span>
                {" - "}
                <span className="text-muted">
                  <a
                    href={`http://${example.site}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {example.site}
                  </a>
                </span>
              </p>
              <p className="my-0">
                <span className="small">{example.subtittle}</span>
              </p>
              <p className="my-0">
                <span className="text-muted small">{example.description}</span>
              </p>
            </div>
          </Fade>
        </Col>
        <Col>
          <Fade cascade>
            <div className="pb-3">
              <div className="my-0">
                <div className="text-center">
                  <a
                    href={`http://${example.site}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={example.image}
                      style={{ width: "300px", height: "auto" }}
                      className="rounded"
                      alt={example.site}
                    />
                  </a>
                </div>
              </div>
            </div>
          </Fade>
        </Col>
      </Row>
    );
  };

  const Details = () => {
    return (
      <div>
        {examples.list.map((example, index) => (
          <Example example={example} index={index} key={index} />
        ))}
      </div>
    );
  };

  return (
    <LayoutSection
      {...props}
      Tittle={() => <Tittle Tittle={examples.tittle} />}
      Details={Details}
    />
  );
};

export default Portifolio;
