import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import classnames from "classnames";

import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Button
} from "reactstrap";

import LOGO from "../assets/img/favicon.jpg";

const NavbarMain = ({ navbarColorNew = "navbar-transparent" }) => {
  const navbarColorDefault = navbarColorNew;

  const [navbarColor, setNavbarColor] = useState(navbarColorDefault);
  const [navbarCollapse, setNavbarCollapse] = useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setNavbarColor(navbarColorDefault);
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <Navbar
      className={classnames("fixed-top", navbarColor)}
      color-on-scroll="300"
      expand="lg"
    >
      <Container>
        <div className="navbar-translate">
          <NavbarBrand
            data-placement="bottom"
            to="/"
            title="Feito por Scarante Tecnologia"
            tag={Link}
          >
            <img
              src={LOGO}
              style={{ width: "30px", height: "30px" }}
              className="rounded img-fluid"
              alt="logo"
            />
          </NavbarBrand>
          <NavbarBrand
            data-placement="bottom"
            to="/"
            title="Feito por Scarante Tecnologia"
            tag={Link}
          >
            <span className="">Scarante Tecnologia</span>
          </NavbarBrand>
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>
            <NavItem>
              <NavLink href="/#products-and-services">
                <p className="" /> Serviços
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/#about-us">
                <p className="" /> Sobre
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://www.linkedin.com/company/scarante-soluções"
                target="_blank"
                title="Siga-nos no LinkedIn"
              >
                <i className="fa fa-linkedin" />
                <p className="d-lg-none">LinkedIn</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://github.com/RenatoScarante"
                target="_blank"
                title="Star on GitHub"
              >
                <i className="fa fa-github" />
                <p className="d-lg-none">GitHub</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <Button
                className="btn-round"
                color="danger"
                href="/#fale-conosco"
              >
                Entre em contato
              </Button>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarMain;
