import React from "react";

import About from "./";
import ABOUT_DATA, { ABOUT_INDEX } from "../../constants/about";

const AboutRenato = () => {
  return <About data={ABOUT_DATA[ABOUT_INDEX.RENATO]} />;
};

export default AboutRenato;
