import { RENATO } from "./Renato";

export const ABOUT_INDEX = {
  RENATO: 0,
  REGIANE: 1,
  RICARDO: 2,
  MAURICIO: 3,
  IRACEMA: 4
};

const ABOUT_DATA = [RENATO];

export default ABOUT_DATA;