import React from 'react';

import { Col, Row } from "reactstrap";

const SectionTittle = props => {
  const { Component } = props;

  return (
    <Row className="h-100 text-lg-right">
      <Col className="">
        <Component {...props} />
      </Col>
    </Row>
  );
};

export default SectionTittle;
