import React from "react";

import SectionTittle from "../components/SectionTittle";
import SectionDetails from "../components/SectionDetails";
import { Col, Container, Row } from "reactstrap";

const Section = props => {
  const { id, index, Tittle, Details } = props;

  const tittleBackgroundColor = index % 2 === 0 ? "#fbfbfb" : "#f8f8f8";
  const detailsBackgroundColor = index % 2 === 0 ? "#fff" : "#fbfbfb";

  return (
    <section id={id}>
      <Container fluid>
        <Row className={props.className}>
          <Col lg="3" style={{ backgroundColor: tittleBackgroundColor }}>
            <SectionTittle Component={Tittle} />
          </Col>
          <Col
            lg="9"
            style={{
              backgroundColor: detailsBackgroundColor
            }}
            className="pr-lg-4"
          >
            <SectionDetails Component={Details} />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Section;
