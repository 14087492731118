import React, { useState } from "react";

import { Fade } from "react-reveal";

import Tittle from "../../../components/Tittle";
import LayoutSection from "../../../layouts/Section";
import { Col, Row } from "reactstrap";

const Experiences = props => {
  const [experiences] = useState(props.data);

  const Experience = ({ experience }) => {
    return (
      <Row>
        <Col>
          <Fade cascade>
            <div className="pb-3">
              <p className="my-0">
                <span className="text-muted small">
                  {experience.start} – {experience.end} ({experience.total})
                </span>
              </p>
              <p className="my-0">
                <span className="text-uppercase font-weight-bold">
                  {experience.company}
                </span>
              </p>
              <p className="my-0">
                <span className="small">{experience.office}</span>
              </p>
              <p className="my-0">
                <span className="text-muted small">{experience.location}</span>
              </p>
            </div>
          </Fade>
        </Col>
        {experience.summary && (
          <Col>
            <Fade cascade>
              <div className="pb-3">
                <div className="my-0">
                  <p className="lead">{experience.summary}</p>
                </div>
              </div>
            </Fade>
          </Col>
        )}
      </Row>
    );
  };

  const Details = () => {
    return (
      <div>
        {experiences.list.map((experience, index) => (
          <Experience experience={experience} index={index} key={index} />
        ))}
      </div>
    );
  };

  return (
    <LayoutSection
      {...props}
      Tittle={() => <Tittle Tittle={experiences.tittle} />}
      Details={Details}
    />
  );
};

export default Experiences;
