import React from "react";

import { Col, Row } from "reactstrap";

const PresentationName = props => {
  const summary = props.data;

  return (
    <div className="text-center text-lg-left">
      <div
        className="my-0 display-4 text-lowercase text-muted"
        style={{ lineHeight: "0.5em" }}
      >
        {summary.name}
      </div>
      <div
        className="my-0 display-4 font-weight-bold text-uppercase text-warning"
        style={{}}
      >
        {summary.lastName}
      </div>
      <Row className="text-center text-lg-left">
        <Col lg="9">
          <p className="lead text-muted">{summary.office}</p>
        </Col>
        <Col lg="3">
          {summary.socialMedia.map((social, index) => (
            <a
              key={index}
              href={social.link}
              target="_blank"
              rel="noopener noreferrer"
              className="mr-2 text-muted"
            >
              <span
                className={`${social.icon}`}
                style={{ fontSize: "1.5em" }}
              />
            </a>
          ))}
        </Col>
      </Row>
    </div>
  );
};

export default PresentationName;
