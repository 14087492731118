import React, { useEffect } from "react";

import Navbar from "components/Navbar";
import Header from "./components/Header";

import ProductsAndServices from "./components/ProductsAndServices";
import AboutUs from "./components/AboutUs";
import Profile from "./components/Profile";
import ContactUs from "./components/ContactUsPipefy";
import Footer from "components/Footer";

const Landing = () => {
  document.documentElement.classList.remove("nav-open");

  useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });

  return (
    <>
      <Navbar />
      <Header />
      <div className="main">
        <ProductsAndServices />
        <AboutUs />
        <Profile />
        <ContactUs />
      </div>
      <Footer />
    </>
  );
};

export default Landing;
