import React from "react";

import { Col } from "reactstrap";

const Contact = props => {
  const { contact } = props;

  return (
    <Col sm="12" md className="text-muted">
      <div className="mb-3">
        <div className="font-weight-bold">{contact.label}</div>
        <div className="">
          {contact.label === "E-mail" ? (
            <a
              href={`mailto:${contact.value}?Subject=Contato`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {contact.value}
            </a>
          ) : contact.label === "Web" ? (
            <a
              href={`http://${contact.value}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {contact.value}
            </a>
          ) : (
            contact.value
          )}
        </div>
      </div>
    </Col>
  );
};

export default Contact;
