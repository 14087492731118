import React, { useState } from "react";

import { Fade } from "react-reveal";

import LayoutSection from "../../../layouts/Section";
import PresentationName from "../../../components/PresentationName";
import Contact from "../../../components/Contact";
import { Col, Row } from "reactstrap";

const Summary = props => {
  const [summary] = useState(props.data);

  const Tittle = () => {
    return (
      <Row className="h-100">
        <Col>
          <Row className="h-100 align-items-center mt-xs-4">
            <Col className="">
              <Fade cascade>
                <div className="text-center p-4">
                  <img
                    src={summary.avatar}
                    style={{ width: "200px", height: "200px" }}
                    className="rounded-circle img-fluid"
                    alt={summary.name}
                  />
                </div>
              </Fade>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const Details = () => {
    return (
      <Row className="h-100">
        <Col>
          <Row className="h-75 align-items-center">
            <Col>
              <PresentationName data={summary} />
            </Col>
          </Row>
          <hr />
          <Row className="h-auto">
            {summary.contacts.map((contact, index) => (
              <Contact contact={contact} index={index} key={index} />
            ))}
          </Row>
        </Col>
      </Row>
    );
  };

  return <LayoutSection Tittle={Tittle} Details={Details} className="vh-100" />;
};

export default Summary;
