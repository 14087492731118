/* eslint-disable */
import React from "react";

import { Row, Container } from "reactstrap";

const FooteItem = ({ href, label }) => {
  return (
    <li>
      <a href={href} target="_blank">
        {label}
      </a>
    </li>
  );
};

const Footer = () => {
  return (
    <footer className="footer footer-black footer-white">
      <Container>
        <Row>
          <div className="credits ml-auto">
            <span className="h6 copyright">
              © {new Date().getFullYear()}, desenvolvido por Scarante Tecnologia
            </span>
          </div>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
