import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { Button, Container, Row, Col } from "reactstrap";

const Profile = () => {
  document.documentElement.classList.remove("nav-open");

  useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <div id="profile" className="section section-gray profile-content">
        <Container>
          <div className="owner">
            <div className="avatar">
              <img
                alt="..."
                className="img-circle img-no-padding img-responsive"
                src={require("assets/img/faces/avatar-renato.jpeg")}
              />
            </div>
            <div className="name">
              <h4 className="title">
                Renato Scarante
                <br />
              </h4>
              <h6 className="description">Desenvolvedor</h6>
            </div>
          </div>
          <Row>
            <Col className="ml-auto mr-auto text-justify" md="6">
              <p>
                Com 37 anos, casado, pai de dois filhos lindos, desde os 15
                anos, diversas empresas de pequeno a grande porte sempre na área
                de tecnologia, também diversas linguagens, sempre como
                full-stack (mesmo antes desse termo se popularizar).
              </p>
              <br />
              <p>
                Bem humorado, simpático, curioso, parceiro, fácil adaptação por
                onde passa. A personalidade juntamente com a experiência
                profissional possibilitam tal feito.
              </p>
              <br />
              <p>
                Depois vários tipos de negócios e várias linguagens, desde um
                grande banco até um pequena empresa de tecnologia, do COBOL ao
                C#, e atualmente com ReactJS / NodeJS (JavaScript) e Microsoft
                Dynamics.
              </p>
              <br />
            </Col>
          </Row>
          <div className="text-center">
            <Button
              className="btn-just-icon btn-neutral ml-1"
              color=""
              href="#pablo"
              onClick={e => e.preventDefault()}
            >
              <i className="fa fa-linkedin" />
            </Button>
            <Button
              className="btn-just-icon btn-neutral ml-1"
              color=""
              href="#pablo"
              onClick={e => e.preventDefault()}
            >
              <i className="fa fa-github" />
            </Button>
            <Button
              className="btn-just-icon btn-neutral ml-1"
              color=""
              href="#pablo"
              onClick={e => e.preventDefault()}
            >
              <i className="fa fa-instagram" />
            </Button>
          </div>
          <br />
          <div className="ml-auto mr-auto text-center">
            <Link to="/renato">
              <Button className="btn-round" color="info">
                Mais detalhes
              </Button>
            </Link>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Profile;
