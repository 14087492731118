import React from "react";

import { Container, Row, Col } from "reactstrap";

const ContactUs = () => {
  return (
    <div id="fale-conosco" className="section landing-section">
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" md="8">
            <h2 className="text-center">Entre em contato</h2>
            <iframe
              width="100%"
              height="580px"
              src="http://app.pipefy.com/public/form/VH9OdXPa?embedded=true"
              frameborder="0"
              title="Contact us form"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactUs;
