import React, { useState } from "react";

import { Fade } from "react-reveal";

import Tittle from "../../../components/Tittle";
import LayoutSection from "../../../layouts/Section";

import { Col, Row } from "reactstrap";

const Intro = props => {
  const [intro] = useState(props.data);

  const Details = () => {
    return (
      <Row>
        <Col>
          {intro.list.map((text, index) => (
            <Fade key={index}>
              <p className="lead text-justify">{text}</p>
              <br />
            </Fade>
          ))}
        </Col>
      </Row>
    );
  };

  return (
    <LayoutSection
      {...props}
      Tittle={() => <Tittle Tittle={intro.tittle} />}
      Details={Details}
    />
  );
};

export default Intro;
