import React from "react";

import { Container, Row, Col } from "reactstrap";

const productsAndServicesItems = [
  {
    tittle: "Produtos",
    description:
      "Com a atual alta demanda de ideias e oportunidades, oferecemos, desenvolvemos e tiramos do papel ideias que facilitem a vida das pessoas. Estamos de olho no mercado!",
    image: require("assets/img/light-buld.png"),
    href: ""
  },
  {
    tittle: "Sob-medida",
    description:
      '"Diga-nos o que desejas, e entregamos o que quereis." Como o próprio título diz, desenvolmemos ou adequamos seu software as suas necessidades, sob-medida mesmo, da maneira mais fácil e prática, pra sair usando o mais rápido possível.',
    image: require("assets/img/development.png"),
    href: ""
  },
  {
    tittle: "Microsoft Dynamics",
    description:
      "Devido ao longa experiência, oferecemos um time altamente capacitado técnico, para o desenvolvimento e customização do seu ERP Microsoft Dynamics. A mão de obra é especializada tecnicamente e funcionalmente. Conte com a gente.",
    image: require("assets/img/microsoft-dynamics-logo.png"),
    href: ""
  }
];

const ProductsAndServicesCard = ({ item }) => {
  return (
    <Col md="4">
      <div className="info">
        <div className="icon icon-info">
          <a href="#pablo" onClick={e => e.preventDefault()}>
            <img alt="..." src={item.image} style={{ height: "60px" }} />
          </a>
        </div>
        <div className="description">
          <h4 className="info-title">{item.tittle}</h4>
          <hr />
          <p className="description">{item.description}</p>
        </div>
      </div>
    </Col>
  );
};

const ProductsAndServices = () => {
  return (
    <div id="products-and-services" className="section text-center">
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" md="8">
            <h2 className="title">Produtos e serviços</h2>
            <h5 className="description">
              Oferecemos o que temos de melhor, seja na criação de produtos
              próprios ou no desenvolvimento de produtos sob-medida. O que
              queremos e tirar do papel sua ideia, descomplicar seu negócio e
              desenvolver seu sonho.
            </h5>
            <br />
          </Col>
        </Row>
        <br />
        <br />
        <Row>
          {productsAndServicesItems.map((item, index) => (
            <ProductsAndServicesCard key={index} item={item} />
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default ProductsAndServices;
