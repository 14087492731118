import React, { useState } from "react";
import { Container } from "reactstrap";

import Navbar from "components/Navbar";
import Footer from "components/Footer";

import Summary from "./components/Summary";
import Intro from "./components/Intro";
import Skills from "./components/Skills";
import Experiences from "./components/Experiences";
import Portifolio from "./components/Portifolio";
import Educations from "./components/Educations";
import Contacts from "./components/Contacts";

const About = props => {
  const [data] = useState(props.data);

  return (
    <>
      <Navbar navbarColorNew="" />
      <Container fluid className="p-0 m-0">
        {data.summary && (
          <Summary data={data.summary} id={"summary"} index={1} />
        )}
        {data.intro && <Intro data={data.intro} id={"intro"} index={2} />}
        {/* { data.expertises && <Expertises data={ data.expertises } id={"expertises"} index={3}/> } */}
        {data.skills && <Skills data={data.skills} id={"skills"} index={3} />}
        {data.experiences && (
          <Experiences data={data.experiences} id={"experiences"} index={4} />
        )}
        {data.portifolio && (
          <Portifolio data={data.portifolio} id={"examples"} index={5} />
        )}
        {data.educations && (
          <Educations data={data.educations} id={"educations"} index={6} />
        )}
        {data.contacts && (
          <Contacts
            data={data.contacts}
            id={"contacts"}
            index={7}
            summary={data.summary}
          />
        )}
      </Container>
      <Footer />
    </>
  );
};

export default About;
